/* eslint-disable */
import router from "./index";
import { getUser } from "@/utils/urlcode";

router.beforeEach(async (to, from, next) => {
  // 添加动态title
  window.document.title = to.name;
  // 判断是否带参数
  if (to.query && to.query.mac) {
    // 获取身份标识
    await getUser();
    next({ path: "/relay" });
  } else {
    next();
  }
});

router.afterEach(async (to) => {
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  }
});
