const state = {
  mac: "",
  placeid: "",
  sign: "",
};

const mutations = {
  SET_MAC: (state, mac) => {
    state.mac = mac;
  },
  SET_PLACEID: (state, placeid) => {
    state.placeid = placeid;
  },
  SET_SIGN: (state, sign) => {
    state.sign = sign;
  },
};

const actions = {
  setUserInfo({ commit }, userInfo) {
    const { mac, placeid, sign } = userInfo;
    return new Promise((resolve) => {
      commit("SET_MAC", mac);
      commit("SET_PLACEID", placeid);
      commit("SET_SIGN", sign);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
