/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "",
    redirect: "/relay",
  },
  {
    path: "",
    name: "",
    redirect: "/relay",
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "异常", requireAuth: true },
    component: () => import("@/components/error/404.vue"),
  },
  {
    path: "/relay",
    name: "Relay",
    meta: { title: "转播配置", requireAuth: true },
    component: () => import("@/views/relay/index.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    component: () => import("@/components/error/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  // history: createWebHashHistory(),
  routes,
});

export default router;
