import store from "@/store/index.js";

/**
 * 获取页面参数-常规
 */
const getUrlCode = () => {
  let url = decodeURI(location.search);
  let theRequest = new Object();
  if (url.indexOf("?") != -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};

/**
 * 获取页面参数-HASH
 */
// const getQueryVariable = () => {
//   const url = decodeURI(window.location.hash).split("?", 2)[1];
//   let theRequest = new Object();
//   if (url) {
//     let strs = url.split("&");
//     for (let i = 0; i < strs.length; i++) {
//       theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
//     }
//   }
//   return theRequest;
// };

/**
 * 读取页面参数获取基础信息
 */
export async function getUser() {
  /*const local = window.location.href;
  const ua = navigator.userAgent.toLowerCase();
  let url = "";
  if (/iphone|ipad|ipod/.test(ua)) {
    url = local.split("#")[0];
  } else if (/android/.test(ua)) {
    url = local;
  }*/
  const urlCode = getUrlCode();
  // const urlCode = getQueryVariable();
  const mac = urlCode.mac;
  const place_id = urlCode.place_id;
  const sign = urlCode.sign;
  if (mac && place_id && sign) {
    // 身份信息存在
    store.dispatch("user/setUserInfo", {
      mac: mac,
      placeid: place_id,
      sign: sign,
    });
  }
}
